var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-roles-list" },
    [
      _c("loading-box", { attrs: { loading: _vm.loading } }),
      _c("div", { staticClass: "green-divider" }),
      _c("b-table", {
        staticClass: "bv-table",
        attrs: {
          striped: "",
          hover: "",
          items: _vm.rolesList,
          fields: _vm.rolesFields,
          "select-mode": "single",
          "selected-variant": "success",
          "th-class": "bv-table-header",
          "current-page": _vm.currentPage,
          "per-page": _vm.perPage
        },
        scopedSlots: _vm._u([
          {
            key: "cell(is_member)",
            fn: function(row) {
              return [
                _c("b-form-checkbox", {
                  on: {
                    change: function($event) {
                      return _vm.activateChange(row.item, $event)
                    }
                  },
                  model: {
                    value: row.item.is_member,
                    callback: function($$v) {
                      _vm.$set(row.item, "is_member", $$v)
                    },
                    expression: "row.item.is_member"
                  }
                })
              ]
            }
          },
          {
            key: "cell(grants)",
            fn: function(row) {
              return [
                _c("b-form-checkbox", {
                  on: {
                    change: function($event) {
                      return _vm.activateChange(row.item, $event)
                    }
                  },
                  model: {
                    value: row.item.grants,
                    callback: function($$v) {
                      _vm.$set(row.item, "grants", $$v)
                    },
                    expression: "row.item.grants"
                  }
                })
              ]
            }
          },
          {
            key: "cell(manually)",
            fn: function(row) {
              return [
                _c("b-form-checkbox", {
                  on: {
                    change: function($event) {
                      return _vm.activateChange(row.item, $event)
                    }
                  },
                  model: {
                    value: row.item.manually,
                    callback: function($$v) {
                      _vm.$set(row.item, "manually", $$v)
                    },
                    expression: "row.item.manually"
                  }
                })
              ]
            }
          }
        ])
      }),
      _c("div", { staticClass: "green-divider" }),
      _c(
        "div",
        { staticClass: "page-bar" },
        [
          _c("b-form-select", {
            staticClass: "page-select",
            attrs: {
              variant: "dark",
              id: "perPageSelect",
              size: "sm",
              options: _vm.pageOptions
            },
            model: {
              value: _vm.perPage,
              callback: function($$v) {
                _vm.perPage = $$v
              },
              expression: "perPage"
            }
          }),
          _c("b-pagination", {
            staticClass: "page-buttons",
            attrs: {
              variant: "dark",
              "total-rows": _vm.rolesList.length,
              "per-page": _vm.perPage,
              align: "fill",
              size: "sm"
            },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }