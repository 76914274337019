<template>
  <div class="admin-roles-list">
    <loading-box v-bind:loading="loading"></loading-box>
    <div class="green-divider"></div>
    <!--    Bootstrap Table-->
    <b-table striped hover :items="rolesList" :fields="rolesFields" select-mode="single" selected-variant="success"
             th-class="bv-table-header" :current-page="currentPage" :per-page="perPage"
             class="bv-table">
      <!--Activated Checkbox Row Element-->
      <template v-slot:cell(is_member)="row">
        <b-form-checkbox v-model="row.item.is_member" v-on:change="activateChange(row.item, $event)"></b-form-checkbox>
      </template>
      <!--Activated Checkbox Row Element-->
      <template v-slot:cell(grants)="row">
        <b-form-checkbox v-model="row.item.grants" v-on:change="activateChange(row.item, $event)"></b-form-checkbox>
      </template>
      <!--Activated Checkbox Row Element-->
      <template v-slot:cell(manually)="row">
        <b-form-checkbox v-model="row.item.manually" v-on:change="activateChange(row.item, $event)"></b-form-checkbox>
      </template>
<!--      &lt;!&ndash;User Actions Row Element&ndash;&gt;-->
<!--      <template v-slot:cell(actions)="row">-->
<!--        <i class="fa fa-times-circle row-icon-button-danger row-action ml-3" v-b-tooltip.hover title="Does nothing!"-->
<!--           @click="userActionDelete(row.item)"></i>-->
<!--      </template>-->
    </b-table>
    <div class="green-divider"></div>
    <div class="page-bar">
      <b-form-select
        variant="dark"
        v-model="perPage"
        id="perPageSelect"
        size="sm"
        :options="pageOptions"
        class="page-select">
      </b-form-select>
      <b-pagination
        variant="dark"
        v-model="currentPage"
        :total-rows="rolesList.length"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="page-buttons"></b-pagination>
    </div>
  </div>
</template>

<script>
import LoadingBox from '../helpers/LoadingBox'

export default {
  name: 'admin-roles-list',
  components: {
    LoadingBox
  },
  props: {
    roles: Array,
    showFields: Array
  },
  data: function () {
    return {
      loading: false,
      // Table Pagination
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 0, text: 'All' }
      ],
      dirty: false,
      rolesFields: [],
      masterFields: [
        { key: 'name',
          label: 'Name',
          sortable: true
        },
        { key: 'is_member',
          label: 'Member ?',
          sortable: true
        },
        { key: 'grants',
          label: 'Grants ?',
          sortable: true
        },
        { key: 'manual',
          label: 'Manually Assigned?',
          sortable: true
        }
      ],
      rolesList: []
    }
  },
  async created () {
    if (this.showFields) {
      this.updateFields(this.showFields)
    } else {
      this.deviceFields = this.masterFields
    }
    if (this.roles) {
      this.rolesList = this.roles
    } else {
      console.error('Subscription List was not given any data!')
    }
  },
  methods: {
    updateFields: function (fieldList) {
      this.rolesFields = this.masterFields.filter(field => fieldList.includes(field.key))
    },
    activateChange: function (item, event) {
      console.log('changed a box')
      this.dirty = true
      this.$emit('change', event)
    }
  },
  watch: {
    roles: function (newval) {
      this.rolesList = newval
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .user-list{
    position: relative;
  }

  .filter-bar {
    display: flex;
    flex-direction: row;
  }

  .page-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .page-select {
    width: 10%;
    min-width: 50px;
  }

  .page-buttons {
    width: 25%;
    min-width: 150px;
  }

  .row-action {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  .child-card {
    background: $theme-color-background-4;
    padding: 10px 20px;
    border: 2px solid $theme-color-primary-3;
  }

  .advanced-filter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px;
    padding: 3px;
    background: $theme-color-background-1;
    border: 1px solid $theme-color-primary-3;
  }

  .filter-label {
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0px 5px;
  }

  .sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    height: calc(1.5em + 0.5rem + 2px);
  }
</style>
